/*
* @Author: Sem van Gelooven
* @Date:   2017-09-22 13:57:36
* @Last Modified by:   Sem van Gelooven
* @Last Modified time: 2017-09-25 14:29:42
*/


'use strict';

// Disable Dropzone auto discover
if($('#dropzone-upload')[0]) {
    Dropzone.autoDiscover = false;
}

$(document).ready(function () {
    /*------------------------------------------------
        Ripple effect buttons (Waves)
    -------------------------------------------------*/
    if($('.btn')[0]) {
        Waves.init();
        Waves.attach('.btn');
    }


    /*------------------------------------------------
        Data Table (DataTables)
    ------------------------------------------------*/
    if($('#data-table')[0]) {

        // Add custom buttons
        var dataTableButtons =  '<div class="dataTables_buttons hidden-sm-down actions">' +
                                    '<span class="actions__item zmdi zmdi-print" data-table-action="print" />' +
                                    '<span class="actions__item zmdi zmdi-fullscreen" data-table-action="fullscreen" />' +
                                    '<div class="dropdown actions__item">' +
                                        '<i data-toggle="dropdown" class="zmdi zmdi-download" />' +
                                        '<ul class="dropdown-menu dropdown-menu-right">' +
                                            '<a href="" class="dropdown-item" data-table-action="excel">Excel (.xlsx)</a>' +
                                            '<a href="" class="dropdown-item" data-table-action="csv">CSV (.csv)</a>' +
                                        '</ul>' +
                                    '</div>' +
                                '</div>';

        // Initiate data-table
        $('#data-table').DataTable({
            autoWidth: false,
            responsive: true,
            lengthMenu: [[15, 30, 45, -1], ['15 Rijen', '30 Rijen', '45 Rijen', 'Alles']],
            language: {
                processing: "Bezig...",
                lengthMenu: "_MENU_ resultaten weergeven",
                zeroRecords: "Geen resultaten gevonden",
                info: "_START_ tot _END_ van _TOTAL_ resultaten",
                infoEmpty: "Geen resultaten om weer te geven",
                infoFiltered: " (gefilterd uit _MAX_ resultaten)",
                infoPostFix: "",
                searchPlaceholder: "Zoek in deze tabel...",
                search: "Zoeken:",
                emptyTable: "Geen resultaten aanwezig in de tabel",
                infoThousands: ".",
                loadingRecords: "Een moment geduld aub - bezig met laden...",
                paginate: {
                    first: "Eerste",
                    last: "Laatste",
                    next: "Volgende",
                    previous: "Vorige"
                },
                aria: {
                    sortAscending:  ": activeer om kolom oplopend te sorteren",
                    sortDescending: ": activeer om kolom aflopend te sorteren"
                },
            },
            dom: 'Blfrtip',
            buttons: [
                {
                    extend: 'excelHtml5',
                    title: 'Export Data'
                },
                {
                    extend: 'csvHtml5',
                    title: 'Export Data'
                },
                {
                    extend: 'print',
                    title: 'Material Admin'
                }
            ],
            "initComplete": function(settings, json) {
                $(this).closest('.dataTables_wrapper').prepend(dataTableButtons);
            },
            "fnDrawCallback": function( oSettings ) {

                $('.remove-item').click(function(e){
                    e.preventDefault();

                    var $this       = $(this), 
                        itemID      = $this.data('item-id'),
                        parentRow   = $this.parents('tr'),
                        form        = $('#delete-form-'+itemID),
                        formAction  = form.attr('action'),
                        formData    = form.serialize();

                    swal({
                        title: 'Weet je het zeker?',
                        text: 'U staat op het punt dit item te verwijderen.',
                        type: 'warning',
                        showCancelButton: true,
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success',
                        confirmButtonText: 'Ja',
                        cancelButtonClass: 'btn btn-danger',
                        cancelButtonText: 'Annuleren',
                        showLoaderOnConfirm: true,
                        preConfirm: function() {
                            return new Promise(function(resolve, reject) {
                                $.ajax({
                                    url: formAction,
                                    data: formData,
                                    type: "post"
                                })
                                .done(function(data){
                                    // remove row
                                    swal.showLoading();
                                    // remove row
                                    parentRow.slideUp();

                                    resolve();
                                })
                                .fail(function(data){
                                    swal('Oops...', 'Er is iets verkeerd gegaan, probeer het opnieuw', 'error');
                                });
                            });
                        },
                        allowOutsideClick: false
                    }).then(function(data) {
                        swal({
                            title: 'Gelukt!',
                            text: 'Het item is succesvol verwijderd',
                            type: 'success',
                            buttonsStyling: false,
                            confirmButtonClass: 'btn btn-primary'
                        });
                    });
                });
            }
        });

        // Add blue line when search is active
        $('.dataTables_filter input[type=search]').focus(function () {
            $(this).closest('.dataTables_filter').addClass('dataTables_filter--toggled');
        });

        $('.dataTables_filter input[type=search]').blur(function () {
            $(this).closest('.dataTables_filter').removeClass('dataTables_filter--toggled');
        });


        // Data table buttons
        $('body').on('click', '[data-table-action]', function (e) {
            e.preventDefault();

            var exportFormat = $(this).data('table-action');

            if(exportFormat === 'excel') {
                $(this).closest('.dataTables_wrapper').find('.buttons-excel').trigger('click');
            }
            if(exportFormat === 'csv') {
                $(this).closest('.dataTables_wrapper').find('.buttons-csv').trigger('click');
            }
            if(exportFormat === 'print') {
                $(this).closest('.dataTables_wrapper').find('.buttons-print').trigger('click');
            }
            if(exportFormat === 'fullscreen') {
                var parentCard = $(this).closest('.card');

                if(parentCard.hasClass('card--fullscreen')) {
                    parentCard.removeClass('card--fullscreen');
                    $('body').removeClass('data-table-toggled');
                }
                else {
                    parentCard.addClass('card--fullscreen')
                    $('body').addClass('data-table-toggled');
                }
            }
        });
    }


    /*------------------------------------------------
        Autosize Textarea (Autosize)
    ------------------------------------------------*/
    if($('.textarea-autosize')[0]) {
        autosize($('.textarea-autosize'));
    }


    /*------------------------------------------------
        Input Mask (jQuery Mask Plugin)
    ------------------------------------------------*/
    if ($('input-mask')[0]) {
        $('.input-mask').mask();
    }

    /*------------------------------------------------
        Select 2
    ------------------------------------------------*/
    if($('select.select2')[0]) {
        var select2parent = $('.select2-parent')[0] ? $('.select2-parent') : $('body');

        $('select.select2').select2({
            dropdownAutoWidth: true,
            width: '100%',
            dropdownParent: select2parent
        });
    }


    /*------------------------------------------------
        Drag n Drop file upload (DropzoneJs)
    ------------------------------------------------*/
    if($('#dropzone-upload')[0]) {
        $('#dropzone-upload').dropzone({
            url: "/file/post",
            addRemoveLinks: true
        });
    }


    /*------------------------------------------------
        Datetime picker (Flatpickr)
    ------------------------------------------------*/
    // Date and time
    if($('.datetime-picker')[0]) {
        $('.datetime-picker').flatpickr({
            enableTime: true,
            dateFormat: "d-m-Y H:i",
            nextArrow: '<i class="zmdi zmdi-long-arrow-right" />',
            prevArrow: '<i class="zmdi zmdi-long-arrow-left" />'
        });
    }

    // Date only
    if($('.date-picker')[0]) {
        $('.date-picker').flatpickr({
            enableTime: false,
            nextArrow: '<i class="zmdi zmdi-long-arrow-right" />',
            prevArrow: '<i class="zmdi zmdi-long-arrow-left" />'
        });
    }

    // Time only
    if($('.time-picker')[0]) {
        $('.time-picker').flatpickr({
            noCalendar: true,
            enableTime: true
        });
    }


    /*------------------------------------------------
        Input slider (noUiSlider)
    ------------------------------------------------*/
    // Single
    if($('#input-slider')[0]) {
        var slider = document.getElementById ('input-slider');

        noUiSlider.create (slider, {
            start: [20],
            connect: 'lower',
            range: {
                'min': 0,
                'max': 100
            }
        });

        slider.noUiSlider.on('update', function( values, handle ) {
            document.getElementById('input-slider-value').value = values[handle];
        });
    }

    // Range
    if($('#input-slider-range')[0]) {
        var sliderRange = document.getElementById ('input-slider-range');
        var sliderRangeUpper = document.getElementById('input-slider-range-value-1');
        var sliderRangeLower = document.getElementById('input-slider-range-value-2');
        var sliderRangeInputs = [sliderRangeUpper, sliderRangeLower]

        noUiSlider.create(sliderRange, {
            start: [20, 80],
            connect: true,
            range: {
                'min': 0,
                'max': 100
            }
        });

        sliderRange.noUiSlider.on('update', function( values, handle ) {
            sliderRangeInputs[handle].value = values[handle];
        });
    }

    // Theme examples
    if($('.input-slider')[0]) {
        var sliderThemes = document.getElementsByClassName('input-slider');

        for ( var i = 0; i < sliderThemes.length; i++ ) {

            noUiSlider.create(sliderThemes[i], {
                start: [20],
                connect: 'lower',
                range: {
                    'min': 0,
                    'max': 100
                }
            });
        }
    }


    /*------------------------------------------------
        Color picker (Bootstrap color picker)
    -------------------------------------------------*/
    if ($('.color-picker')[0]) {
        $(this).each(function () {
            var horizontal = $(this).find('.color-picker__value').data('horizontal') || false;

            $(this).find('.color-picker__value').colorpicker({
                horizontal: horizontal
            });
        });

        $('body').on('change', '.color-picker__value', function () {
            $(this).closest('.color-picker').find('.color-picker__preview').css('backgroundColor', $(this).val());
        });
    }


    /*------------------------------------------------
        WYSIWYG editor (Trumbowyg)
    -------------------------------------------------*/
    if($('.wysiwyg-editor')[0]) {
        $('.wysiwyg-editor').trumbowyg({
            autogrow: true,
            btns: [
                ['viewHTML'],
                ['formatting'],
                ['link'],
                ['insertImage'],
                'btnGrp-justify',
                'btnGrp-lists',
                ['horizontalRule'],
                ['removeformat'],
                ['fullscreen']
            ]
        });
    }


    /*------------------------------------------------
        Lightbox (LightGallery)
    -------------------------------------------------*/
    if ($('.lightbox')[0]) {
        $('.lightbox').lightGallery({
            enableTouch: true
        });
    }


    /*------------------------------------------------
        Popovers (Bootstrap)
    -------------------------------------------------*/
    if($('[data-toggle="popover"]')[0]) {
        $('[data-toggle="popover"]').popover();
    }


    /*------------------------------------------------
        Tooltip (Bootstrap)
    -------------------------------------------------*/
    if($('[data-toggle="tooltip"]')[0]) {
        $('[data-toggle="tooltip"]').tooltip();
    }


    /*------------------------------------------------
        Calendar Widget
    ------------------------------------------------*/
    if($('.widget-calendar__body')[0]) {
        $('.widget-calendar__body').fullCalendar({
            contentHeight: 'auto',
            theme: false,
            buttonIcons: {
                prev: ' zmdi zmdi-long-arrow-left',
                next: ' zmdi zmdi-long-arrow-right'
            },
            header: {
                right: 'next',
                center: 'title, ',
                left: 'prev'
            },
            defaultDate: '2016-08-12',
            editable: true,
            events: [
                {
                    title: 'Dolor Pellentesque',
                    start: '2016-08-01',
                    className: 'bg-cyan'
                },
                {
                    title: 'Purus Nibh',
                    start: '2016-08-07',
                    className: 'bg-amber'
                },
                {
                    title: 'Amet Condimentum',
                    start: '2016-08-09',
                    className: 'bg-green'
                },
                {
                    title: 'Tellus',
                    start: '2016-08-12',
                    className: 'bg-blue'
                },
                {
                    title: 'Vestibulum',
                    start: '2016-08-18',
                    className: 'bg-cyan'
                },
                {
                    title: 'Ipsum',
                    start: '2016-08-24',
                    className: 'bg-teal'
                },
                {
                    title: 'Fringilla Sit',
                    start: '2016-08-27',
                    className: 'bg-blue'
                },
                {
                    title: 'Amet Pharetra',
                    url: 'http://google.com/',
                    start: '2016-08-30',
                    className: 'bg-amber'
                }
            ]
        });

        //Display Current Date as Calendar widget header
        var mYear = moment().format('YYYY');
        var mDay = moment().format('dddd, MMM D');
        $('.widget-calendar__year').html(mYear);
        $('.widget-calendar__day').html(mDay);
    }

    /*------------------------------------------------
        Notes line clamp (Succinct)
    ------------------------------------------------*/
    if($('.notes__body')[0]) {
        var clamp;

        $('.notes__body').each(function(index, element) {
            if($(this).prev().is('.notes__title')) {
                clamp = 4;
            }
            else {
                clamp = 6;
            }

            $clamp(element, { clamp: clamp });
        });
    }


    /*----------------------------------------------------------
        Custom Scrollbars (jQuery.scrollbar and ScrollLock)
    -----------------------------------------------------------*/
    if($('.scrollbar-inner')[0]) {
        $('.scrollbar-inner').scrollbar().scrollLock();
    }


    /*----------------------------------------------------------
        Froala
    -----------------------------------------------------------*/
    var editor = $('.editor');

    if( editor.length > 0 ) {

		editor.each(function() {
			
			var editor = $(this),
				options = {
					theme: 'default',
					key: 'cB8A6D6E6C2A2C-8C2H2C4B3B6B2D1C4C1G2qAJb1Nd1ZWPXDa1FQd1E==',
					language: 'nl',
					height: editor.data('height') !== "undefined" ? editor.data('height') : null,
					imageAllowedTypes: ['jpeg', 'jpg', 'gif'],
					imageDefaultDisplay: 'block',
					imageDefaultWidth: 0,
					imageEditButtons: [
						'imageAlign', 
						'imageReplace', 
						'imageLink',
						'linkOpen',
						'linkEdit',
						'linkRemove',     
						'imageAlt',
						'imageRemove'
					],
					imageInsertButtons: ['imageBack', '|', 'imageUpload'],
					imageUploadParams: {
						_token: $('meta[name="csrf-token"]').attr('content')
					},
					imageUploadURL: '/admin/froala/upload-image',
					videoAllowedProviders: ['youtube', 'vimeo'],
					videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
					fileAllowedTypes: ['application/pdf'],
					fileUploadParams: {
						_token: $('meta[name="csrf-token"]').attr('content')
					},
					fileUploadURL: '/admin/froala/upload-file',
					wordDeniedAttrs: ['style'],
					paragraphFormat: {
						N: 'Normaal',
						H1: 'Kop 1',
						H2: 'Kop 2',
						H3: 'Kop 3'
					},
					linkInsertButtons: ['linkBack'],
					linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
					paragraphFormatSelection: true,
					quickInsertButtons: ['image', 'video', 'ul', 'ol', 'hr'],
					placeholderText: editor.data('placeholder_text') !== "undefined" ? editor.data('placeholder_text') : 'Begin hier met typen...',
					toolbarInline: editor.data('toolbar_inline') !== "undefined" ? editor.data('toolbar_inline') : false,
					charCounterCount: editor.data('char_counter_count') !== "undefined" ? editor.data('char_counter_count') : true,
					toolbarButtons: [
						'paragraphFormat', 'bold', 'italic', 'underline', '|', 
						'formatOL', 'formatUL', '|', 
						'insertLink', 'insertImage', 'insertVideo', 'insertFile', '|', 
						'selectAll', 'clearFormatting', 'html'
					]
				};
				
			editor.froalaEditor(options)
			.on('froalaEditor.image.inserted', function(e, editor, $img, response) {
				$img.addClass('img-responsive');
			})
			.on('froalaEditor.image.removed', function (e, editor, $img) {
				$.ajax({
					method: 'POST',
					url: '/admin/froala/delete-image',
					data: {
						src: $img.attr('src'),
						_token: $('meta[name="csrf-token"]').attr('content')
					}
				});
			})
			.on('froalaEditor.file.unlink', function (e, editor, link) {
				$.ajax({
					method: 'POST',
					url: '/admin/froala/delete-file',
					data: {
						src: $(link).attr('href'),
						_token: $('meta[name="csrf-token"]').attr('content')
					}
				});
			});
		});
    }

    /*----------------------------------------------------------
        Froala MINI ( JH Group ) editor
    -----------------------------------------------------------*/
    var $minieditor = $('.editor-mini');

    if( $minieditor.length > 0 ) {

        $minieditor.each(function() {
            
            var $minieditor = $(this),
                options = {
                    theme: 'default',
                    key: 'cB8A6D6E6C2A2C-8C2H2C4B3B6B2D1C4C1G2qAJb1Nd1ZWPXDa1FQd1E==',
                    language: 'nl',
                    height: $minieditor.data('height') !== "undefined" ? $minieditor.data('height') : null,
                    imageAllowedTypes: ['jpeg', 'jpg', 'gif'],
                    imageDefaultDisplay: 'block',
                    imageDefaultWidth: 0,
                    imageEditButtons: [
                        'imageAlign', 
                        'imageReplace', 
                        'imageLink',
                        'linkOpen',
                        'linkEdit',
                        'linkRemove',     
                        'imageAlt',
                        'imageRemove'
                    ],
                    imageInsertButtons: ['imageBack', '|', 'imageUpload'],
                    imageUploadParams: {
                        _token: $('meta[name="csrf-token"]').attr('content')
                    },
                    imageUploadURL: '/admin/froala/upload-image',
                    videoAllowedProviders: ['youtube', 'vimeo'],
                    videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
                    fileAllowedTypes: ['application/pdf'],
                    fileUploadParams: {
                        _token: $('meta[name="csrf-token"]').attr('content')
                    },
                    fileUploadURL: '/admin/froala/upload-file',
                    wordDeniedAttrs: ['style'],
                    paragraphFormat: {
                        N: 'Normaal',
                        H1: 'Kop 1',
                        H2: 'Kop 2',
                        H3: 'Kop 3'
                    },
                    linkInsertButtons: ['linkBack'],
                    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
                    paragraphFormatSelection: true,
                    quickInsertButtons: ['image', 'video', 'ul', 'ol', 'hr'],
                    placeholderText: $minieditor.data('placeholder_text') !== "undefined" ? $minieditor.data('placeholder_text') : 'Begin hier met typen...',
                    toolbarInline: $minieditor.data('toolbar_inline') !== "undefined" ? $minieditor.data('toolbar_inline') : false,
                    charCounterCount: $minieditor.data('char_counter_count') !== "undefined" ? $minieditor.data('char_counter_count') : true,
                    toolbarButtons: [
                        'paragraphFormat', 'bold', 'italic', 'underline', '|', 
                        'formatOL', 'formatUL', '|', 
                        'insertLink', '|', 
                        'selectAll', 'clearFormatting', 'html'
                    ]
                };
                
            $minieditor.froalaEditor(options)
            .on('froalaEditor.image.inserted', function(e, editor, $img, response) {
                $img.addClass('img-responsive');
            })
            .on('froalaEditor.image.removed', function (e, editor, $img) {
                $.ajax({
                    method: 'POST',
                    url: '/admin/froala/delete-image',
                    data: {
                        src: $img.attr('src'),
                        _token: $('meta[name="csrf-token"]').attr('content')
                    }
                });
            })
            .on('froalaEditor.file.unlink', function (e, editor, link) {
                $.ajax({
                    method: 'POST',
                    url: '/admin/froala/delete-file',
                    data: {
                        src: $(link).attr('href'),
                        _token: $('meta[name="csrf-token"]').attr('content')
                    }
                });
            });
        });
    }

	
	/*----------------------------------------------------------
        Froala inline editor
    -----------------------------------------------------------*/
	var $inlineEditor = $('.inline-editor');

    if( $inlineEditor.length > 0 ) {

		$inlineEditor.each(function() {
			
			var editor = $(this),
				options = {
					theme: 'default',
					key: 'cB8A6D6E6C2A2C-8C2H2C4B3B6B2D1C4C1G2qAJb1Nd1ZWPXDa1FQd1E==',
					language: 'nl',
					linkInsertButtons: ['linkBack'],
					linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
					paragraphFormatSelection: false,
					placeholderText: editor.data('placeholder_text') !== "undefined" ? editor.data('placeholder_text') : 'Begin hier met typen...',
					toolbarInline: true,
					charCounterCount: false,
					toolbarButtons: [
						'bold', 'italic', 'underline', '|', 
						'insertLink', '|', 
						'clearFormatting', 'html'
					],
					multiLine: false,
					enter: $.FroalaEditor.ENTER_BR,
					quickInsertButtons: [],
					quickInsertTags: [],
					pluginsEnabled: [
						"codeBeautifier",
						"codeView",
						"colors",
						"entities",
						"fontFamily",
						"fontSize",
						"link",
						"wordPaste"
					],
					editorClass: 'form-control'
				};
				
			editor.froalaEditor(options)
			.on('froalaEditor.focus', function (e, editor) {
				$(this).parent().find('.form-group__bar').addClass('active');
			})
			.on('froalaEditor.blur', function (e, editor) {
				$(this).parent().find('.form-group__bar').removeClass('active');
			});
		});
    }
});
/*
* @Author: Sem van Gelooven
* @Date:   2017-09-22 13:57:20
* @Last Modified by:   Sem van Gelooven
* @Last Modified time: 2017-10-03 15:04:31
*/


'use strict';

/*------------------------------------------------
    Page Loader
-------------------------------------------------*/
$(window).on('load', function () {
    setTimeout(function () {
        $('.page-loader').fadeOut();
    }, 500);
});


$(document).ready(function () {
    /*------------------------------------------------
        Theme Switch
    -------------------------------------------------*/
    $('body').on('change', '.theme-switch input:radio', function () {
        var theme = $(this).val();

        $('body').attr('data-ma-theme', theme);
    });

    /*------------------------------------------------
        Slugify
    -------------------------------------------------*/
    var slugifyEl = $('input#slug'),
        slugifySource = $(slugifyEl.data('source'));

    if( slugifyEl.length > 0 ) {
        slugifyEl.slugify(slugifySource);

        slugifySource.on('input', function(e) {

            if(this.value) {
                slugifyEl.addClass('form-control--active');
            } else {
                slugifyEl.removeClass('form-control--active');
            }
        });
    }

    /*------------------------------------------------
        Search
    -------------------------------------------------*/

    // Active Stat
    $('body').on('focus', '.search__text', function () {
        $(this).closest('.search').addClass('search--focus');
    });

    // Clear
    $('body').on('blur', '.search__text', function () {
        $(this).val('');
        $(this).closest('.search').removeClass('search--focus');
    });


    /*------------------------------------------------
        Sidebar toggle menu
    -------------------------------------------------*/
    $('body').on('click', '.navigation__sub > a', function (e) {
        e.preventDefault();

        $(this).parent().toggleClass('navigation__sub--toggled');
        $(this).next('ul').slideToggle(250);
    });


    /*------------------------------------------------
        Form group blue line
    -------------------------------------------------*/
    if($('.form-group--float')[0]) {
        $('.form-group--float').each(function () {
            var p = $(this).find('.form-control').val()

            if(!p.length == 0) {
                $(this).find('.form-control').addClass('form-control--active');
            }
        });

        $('body').on('blur', '.form-group--float .form-control', function(){
            var i = $(this).val();

            if (i.length == 0) {
                $(this).removeClass('form-control--active');
            }
            else {
                $(this).addClass('form-control--active');
            }
        });
    }

    /*------------------------------------------------
        Remove item function
    -------------------------------------------------*/

    $('.remove-item').click(function(e){
        e.preventDefault();

        var $this       = $(this), 
            itemID      = $this.data('item-id'),
            parentRow   = $this.parents('tr'),
            form        = $('#delete-form-'+itemID),
            formAction  = form.attr('action'),
            formData    = form.serialize();

        swal({
            title: 'Weet je het zeker?',
            text: 'U staat op het punt dit item te verwijderen.',
            type: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success',
            confirmButtonText: 'Ja',
            cancelButtonClass: 'btn btn-danger',
            cancelButtonText: 'Annuleren',
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    $.ajax({
                        url: formAction,
                        data: formData,
                        type: "post"
                    })
                    .done(function(data){
                        // remove row
                        swal.showLoading();
                        // remove row
                        parentRow.slideUp();

                        resolve();
                    })
                    .fail(function(data){
                        swal('Oops...', 'Er is iets verkeerd gegaan, probeer het opnieuw', 'error');
                    });
                });
            },
            allowOutsideClick: false
        }).then(function(data) {
            swal({
                title: 'Gelukt!',
                text: 'Het item is succesvol verwijderd',
                type: 'success',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-primary'
            });
        });
    });
    
    /*------------------------------------------------
        Deactivate item function use this for soft deletes
    -------------------------------------------------*/

    $('table').on('click', '.deactivate-item', function(e){
        e.preventDefault();

        var $this       = $(this), 
            itemID      = $this.data('item-id'),
            parentRow   = $this.parents('tr'),
            form        = $('#destroy-form-'+itemID),
            formAction  = form.attr('action'),
            formData    = form.serialize();

        swal({
            title: 'Weet je het zeker?',
            text: 'U staat op het punt dit item te deactiveren.',
            type: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success',
            confirmButtonText: 'Ja',
            cancelButtonClass: 'btn btn-danger',
            cancelButtonText: 'Annuleren',
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    $.ajax({
                        url: formAction,
                        data: formData,
                        type: "post"
                    })
                    .done(function(data){
                        // remove row
                        swal.showLoading();
                        
                        parentRow.find(".deactivate-item").hide();
                        parentRow.find(".activate-item").show();
                        parentRow.find(".item-active").toggleClass("item-active item-inactive");
                        
                        resolve();
                    })
                    .fail(function(data){
                        swal('Oops...', 'Er is iets verkeerd gegaan, probeer het opnieuw', 'error');
                    });
                });
            },
            allowOutsideClick: false
        }).then(function(data) {
            swal({
                title: 'Gelukt!',
                text: 'Het item is succesvol gedeactiveerd',
                type: 'success',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-primary'
            });
        });
    });
    
    /*------------------------------------------------
        Activate item function use this for soft deletes
    -------------------------------------------------*/

    $('table').on('click', '.activate-item', function(e){
        e.preventDefault();

        var $this       = $(this), 
            itemID      = $this.data('item-id'),
            parentRow   = $this.parents('tr'),
            form        = $('#restore-form-'+itemID),
            formAction  = form.attr('action'),
            formData    = form.serialize();

        swal({
            title: 'Weet je het zeker?',
            text: 'U staat op het punt dit item te activeren.',
            type: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success',
            confirmButtonText: 'Ja',
            cancelButtonClass: 'btn btn-danger',
            cancelButtonText: 'Annuleren',
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    $.ajax({
                        url: formAction,
                        data: formData,
                        type: "post"
                    })
                    .done(function(data){
                        // remove row
                        swal.showLoading();
                        
                        parentRow.find(".activate-item").hide();
                        parentRow.find(".deactivate-item").show();
                        parentRow.find(".item-inactive").toggleClass("item-inactive item-active");
                        
                        resolve();
                    })
                    .fail(function(data){
                        swal('Oops...', 'Er is iets verkeerd gegaan, probeer het opnieuw', 'error');
                    });
                });
            },
            allowOutsideClick: false
        }).then(function(data) {
            swal({
                title: 'Gelukt!',
                text: 'Het item is succesvol geactiveerd',
                type: 'success',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-primary'
            });
        });
    });
	
	/*------------------------------------------------
        Table sortable
    -------------------------------------------------*/
	
	var $tableSortable = $('table.table-sortable tbody');
	
	if($tableSortable.length) {
		
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});
		
		$tableSortable.sortable({
			stop: function( event, ui) {
				
				swal.showLoading();
				
				var action				= $(this).parent('table').data('action'),
					itemId				= ui.item.data('id'),
					moveTo				= ui.item[0].nextElementSibling ? 'left' : 'right',
					relativeToItemId	= ui.item[0].nextElementSibling ? ui.item[0].nextElementSibling.dataset.id : ui.item[0].previousElementSibling.dataset.id;
				
				console.log(action);
				
				$.ajax({
					url: action,
					data: {"item_id": itemId, "move_to": moveTo, "relative_to_item_id": relativeToItemId},
					type: "post"
				})
				.done(function(data){
					
					if(data.status === "success") {
						swal({
							title: 'Gelukt!',
							text: 'Het item is succesvol verplaatst',
							type: 'success',
							buttonsStyling: false,
							confirmButtonClass: 'btn btn-primary'
						});
					} else {
						swal('Oops...', 'Er is iets verkeerd gegaan, probeer het opnieuw', 'error');
					}
					
				})
				.fail(function(data){
					swal('Oops...', 'Er is iets verkeerd gegaan, probeer het opnieuw', 'error');
				});
			}
		});
	}
	
	
    /*------------------------------------------------
        Menu's sortable list
    -------------------------------------------------*/
    var menu = $('.sortable').nestedSortable({
        handle: 'div',
        items: 'li',
        toleranceElement: '> div',
        isTree: true,
        forcePlaceholderSize: true,
        placeholder: 'placeholder',
        maxLevels: 4,
        // disableParentChange: true,
        relocate: function(i, item){

            var tree = menu.nestedSortable('toArray', {excludeRoot: 1});

            $.post('/admin/menu/save', {'tree': tree}, function(data) {
                // Do something...
            });
        }
    });


    /*------------------------------------------------
        Show template in field ( select )
    -------------------------------------------------*/

    var jobCandidateMailForm = $('#job_candidate_mail_form');

    if(jobCandidateMailForm.length > 0) {

        $('#template').change(function (){

            var template_id = $(this).val();
            var body_text = $('#body');

            if(template_id > 0)
            {

                $.ajax({
                    url: '/admin/template/' + template_id,
                    type: 'GET',
                    success: function(result) {

                        if(result.status == 'ok')
                        {
  
                            if ($('.fr-wrapper').hasClass('show-placeholder') ) {
                                $('.fr-wrapper').removeClass('show-placeholder').delay(50).queue(function(){
                                    $('.fr-element').html('').html(result.bodyText).dequeue();
                                });
                            }
                            else
                            {
                                $('.fr-element').html('').html(result.bodyText);
                            }

                            $('.form-error-message').hide();
                        }
                        else
                        {
                            $('.form-error-message').show();
                        }

                    }
                });
            }
        });
    }

    /*------------------------------------------------
        Show different fields -> change category
    -------------------------------------------------*/

    var jobForm = $('#job_form');

    if(jobForm.length > 0)
    {
        $('#job_category_id').change(function (){

            var category_id = $(this).val();

            if(category_id == 1)
            {
                $('#div_job_workingareas').show();
            }
            else if(category_id == 2)
            {
                $('#div_job_workingareas').hide(); 
            }
            else
            {
                $('#div_job_workingareas').hide();
            }

        });


        var job_category_id = $('#job_category_id').val();

        if(job_category_id == 1)
        {
            $('#div_job_workingareas').show();
        }
        else if(job_category_id == 2)
        {
            $('#div_job_workingareas').hide(); 
        }
        else
        {
            $('#div_job_workingareas').hide();
        }
    }


    /*------------------------------------------------
        Show other reject reason
    -------------------------------------------------*/

    var rejectForm = $('#job_candidate_status_form');

    if(rejectForm.length > 0)
    {

        var status_current = $('#status').val();

        if(status_current == 'rejection')
        {
            $('#div_reject_reason').show();
        }
        else
        {
            $('#div_reject_reason').hide();
        }

        var reject_reason_current = $('#reject_reason').val();

        if(reject_reason_current == 'other')
        {
            $('#div_reject_reason_other').show();
        }
        else
        {
            $('#div_reject_reason_other').hide();
        }

        $('#status').change(function (){

            var status = $(this).val();

            if(status == 'rejection')
            {
                $('#div_reject_reason').show();
            }

        });

        $('#reject_reason').change(function (){

            var reject_reason = $(this).val();

            if(reject_reason == 'other')
            {
                $('#div_reject_reason_other').show();
            }
            else
            {
                $('#div_reject_reason_other').hide();
            }

        });
        
    }

    var mailForm = $('#job_candidate_mail_form');

    if(mailForm.length > 0)
    {

        var status_current = $('#status').val();

        if(status_current == 'rejection')
        {
            $('#div_reject_reason').show();
        }
        else
        {
            $('#div_reject_reason').hide();
        }

        var reject_reason_current = $('#reject_reason').val();

        if(reject_reason_current == 'other')
        {
            $('#div_reject_reason_other').show();
        }
        else
        {
            $('#div_reject_reason_other').hide();
        }

        $('#status').change(function (){

            var status = $(this).val();

            console.log(status);

            if(status == 'rejection')
            {
                $('#div_reject_reason').show();
            }

        });

        $('#reject_reason').change(function (){

            var reject_reason = $(this).val();

            if(reject_reason == 'other')
            {
                $('#div_reject_reason_other').show();
            }
            else
            {
                $('#div_reject_reason_other').hide();
            }

        });
        
    }

});


/*
* @Author: Sem van Gelooven
* @Date:   2017-09-22 13:58:01
* @Last Modified by:   Sem van Gelooven
* @Last Modified time: 2017-09-22 13:58:03
*/


'use strict';

$(document).ready(function () {
    var $body = $('body');

    //Fullscreen Launch function
    function launchIntoFullscreen(element) {

        if(element.requestFullscreen) {
            element.requestFullscreen();
        } else if(element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if(element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if(element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }

    //Fullscreen exit function
    function exitFullscreen() {

        if(document.exitFullscreen) {
            document.exitFullscreen();
        } else if(document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if(document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }


    $body.on('click', '[data-ma-action]', function (e) {
        e.preventDefault();

        var $this = $(this);
        var action = $this.data('ma-action');
        var target = '';

        switch (action) {
            /*-------------------------------------------
                Search
            ---------------------------------------------*/
            // Open
            case 'search-open':
                $('.search').addClass('search--toggled');
                break;

            // Close
            case 'search-close':
                $('.search').removeClass('search--toggled');
                break;


            /*-------------------------------------------
                Aside
            ---------------------------------------------*/
            // Open
            case 'aside-open':
                target = $this.data('ma-target');
                $this.addClass('toggled')
                $(target).addClass('toggled');
                $('.content, .header').append('<div class="ma-backdrop" data-ma-action="aside-close" data-ma-target='+target+' />');
                break;


            case 'aside-close':
                target = $this.data('ma-target');
                $('[data-ma-action="aside-open"], '+target).removeClass('toggled');
                $('.content, .header').find('.ma-backdrop').remove();
                break;


            /*-------------------------------------------
                Full screen browse
            ---------------------------------------------*/
            case 'fullscreen':
                launchIntoFullscreen(document.documentElement);
                break;


            /*-------------------------------------------
                Print
            ---------------------------------------------*/
            case 'print':
                window.print();
                break;


            /*-------------------------------------------------
                Clear local storage (SweetAlert 2 required)
            --------------------------------------------------*/
            case 'clear-localstorage':
                swal({
                    title: 'Are you sure?',
                    text: 'This can not be undone!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, clear it',
                    cancelButtonText: 'No, cancel'
                }).then(function() {
                    localStorage.clear();
                    swal(
                        'Cleared!',
                        'Local storage has been successfully cleared',
                        'success'
                    );
                });
                break;


            /*-------------------------------------------
                Login
            --------------------------------------------*/
            case 'login-switch':
                target = $this.data('ma-target');
                $('.login__block').removeClass('active');
                $(target).addClass('active');
                break;


            /*-------------------------------------------
                Notifications clear
            --------------------------------------------*/
            case 'notifications-clear':
                e.stopPropagation();

                var items = $('.top-nav__notifications .listview__item');
                var itemsCount = items.length;
                var index = 0;
                var delay = 150;

                $this.fadeOut();

                items.each(function () {
                    var currentItem = $(this);
                    setTimeout(function () {
                        currentItem.addClass('animated fadeOutRight');
                    }, index+=delay);
                });

                setTimeout(function () {
                    items.remove();
                    $('.top-nav__notifications').addClass('top-nav__notifications--cleared');
                }, itemsCount*180);
                break;


            /*------------------------------------------------
                Toolbar search toggle
            -------------------------------------------------*/

            // Open
            case 'toolbar-search-open':
                $(this).closest('.toolbar').find('.toolbar__search').fadeIn(200);
                $(this).closest('.toolbar').find('.toolbar__search input').focus();
                break;

            // Close
            case 'toolbar-search-close':
                $(this).closest('.toolbar').find('.toolbar__search input').val('');
                $(this).closest('.toolbar').find('.toolbar__search').fadeOut(200);
                break;
        }
    }); 
});